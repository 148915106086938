// Translated
// Migrated
<template>
  <div class="side-menu-dropdown-list overflow-x-hidden mt-2 lg:-mt-5">
    <button
      class="btn btn-link submenu-close lg:hidden text-white"
      @click="close(false)"
    >
      <fa icon="times" />
    </button>
    <nuxt-link
      class="bg-blue !py-4 submenu-title text-left font-bold"
      data-i18n="seeAllCategory"
      :to="categoryLink.url"
      @click="close(true)"
    >
      {{ $t('seeAllCategory') }}
      <fa
        class="ml-2 h5"
        icon="arrow-right"
      />
    </nuxt-link>
    <simplebar
      class="submenu-columns bg-white"
      data-simplebar-auto-hide="false"
      data-simplebar-scrollbar
      data-simplebar-scrollbar-min-size="20"
    >
      <ul class="submenu-column pr-4">
        <li
          v-for="(trip, index) in sortedTrips"
          :key="index"
        >
          <template v-if="translationActive">
            <div class="font-mono truncate rounded-[1.25rem] hover:bg-white-blue hover:bold">
              <span :data-translation="trip?.tripname_translationkey">
                {{ trip.tripname }}
              </span>
              <span
                class="ml-1"
                :data-translation="trip?.url_translationkey"
              >{{ trip.url }}</span>
            </div>
          </template>
          <template v-else>
            <nuxt-link
              class="truncate rounded-[1.25rem] hover:bg-white-blue hover:bold"
              :to="trip.url"
              @click="close(true)"
            >
              <span>
                {{ trip.tripname }}
              </span>
            </nuxt-link>
          </template>
        </li>
      </ul>
    </simplebar>
  </div>
</template>

<script>
import { faList, faGlobe } from '@fortawesome/pro-solid-svg-icons'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
import { mapState, mapActions } from 'pinia'

const icons = {
  faList,
  faGlobe,
}

export default defineNuxtComponent({
  props: {
    trips: {
      type: Array,
      required: true,
    },

    categoryLinks: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    titleTranslation: {
      type: String,
      default: null,
    },
  },

  emits: ['close'],

  data () {
    return {
      window: 0,
    }
  },

  computed: {
    ...mapState(useLocaleStore, {
      translationActive: 'fullTranslationSuiteActive',
    }),

    sortedTrips () {
      return [...(this.trips || [])]
        .sort((a, b) => a?.tripname > b?.tripname ? 1 : -1)
    },

    categoryLink () {
      return this.categoryLinks?.[0]
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.window = window.innerWidth
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useMenuStore, ['closeSideMenuActive']),

    resizeListener () {
      if (window.innerWidth === this.windowWidth) {
        return
      }
      this.windowWidth = window.innerWidth
    },

    icon (icon) {
      if (icon === 'calendar') {
        return faCalendar
      }

      const iconClass = 'fa' + icon.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')

      return icons[iconClass] || icon
    },

    close (closeMenu = false) {
      if (closeMenu) {
        this.closeSideMenuActive()
      }
      this.$emit('close')
    },
  },
})
</script>

<style lang="scss">
@import "simplebar-vue/dist/simplebar.min.css";
.submenu-column {
  .font-mono span {
    width: 48%;
    overflow: hidden;
    display: inline-block;
    letter-spacing: -2px;
  }
}
</style>
